import { OPEN_SELECTOR, CLOSE_SELECTOR} from '../actions/selectorContratos';


const defaultState = false;

function reducer(state = defaultState, { type }) {
    switch (type) {
        case OPEN_SELECTOR: {
            return true;
        }
        case CLOSE_SELECTOR: {
            return false;
        }
        default:
            return state;
    }
}

export default reducer;