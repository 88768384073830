import { SET_USUARIO } from '../actions/dataUsuario';

const defaultState = null;

function reducer(state = defaultState, { type, usuario }) {

  switch (type) {
    case SET_USUARIO: {
      return usuario;
    }
    default:
      return state;
  }
}

export default reducer;