import Axios from "../axios";
import { realTime } from "../../firebase";

export const getUsuario = async (id) => {
  return new Promise((resolve, reject) => {
    var base = process.REACT_APP_DB_URL;
    Axios.get(`${base}/usuarios/uid/${id}`, {})
      .then((res) => {
        if (res.data) resolve(res.data);
        else resolve(null);
      })
      .catch((error) => {
        console.error(error);
        resolve(error);
      });
  });
};

export const guardarUsuario = async (idContrato, user) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/usuarios/" + user.uid)
      .set(user);
    resolve(user);
  });
};

export const guardarUsuarioAdmin = async (idContrato, user) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/usuarios_admin/" + user.uid)
      .set(user);
    resolve(user);
  });
};

export const eliminarUsuario = async (idContrato, user) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/usuarios/" + user)
      .set({});
    resolve({});
  });
};

export const eliminarUsuarioAdmin = async (idContrato, user) => {
  console.log(user);
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/usuarios_admin/" + user)
      .set({});
    resolve({});
  });
};

export const getListadoUsuarios = async (idContrato) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/usuarios")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
          }));

          resolve(registrosList);
        }
        resolve([]);
      });
  });
};

export const getListadoUsuariosAdmin = async (idContrato) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/usuarios_admin")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
          }));

          resolve(registrosList);
        }
        resolve([]);
      });
  });
};

export const getContratosUsuario = async (id) => {
  return new Promise((resolve, reject) => {
    var base = process.env.REACT_APP_DB_URL;
    Axios.get(`${base}/usuarios/${id}/contratos-visibles`, {})
      .then((res) => {
        if (res.data && res.data.contratos_visibles_ref)
          resolve(res.data.contratos_visibles_ref);
        else resolve(null);
      })
      .catch((error) => {
        console.error(error);
        resolve(error);
      });
  });
};
