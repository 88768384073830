import React, { useState, createContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { CssBaseline } from "@material-ui/core";
import AppBar from "./components/appBar";
import LoadPage from "./containers/loadPage";
import * as ROUTES from "./constants/routes";

import Config from "./containers/Configuracion";
import Listado from "./containers/ListadoAST";
import "./App.css";
import axios from "axios";
import { auth, onAuthStateChange } from "./services/firebase";
import * as URLS from "./constants/urls";
import {
  obtenerDatosSesionUsuario,
  getUsuario_contrato,
} from "./services/request/requestUsuarios";

import { getContrato } from "./services/database/contrato";
import { getContratoPorCodigo } from "./services/request/requestPortafolio";
import SelectorContratos from "./containers/selectorContrato";

const drawerWidth = 300;
const appbarHeight = 18;

const useStyles = makeStyles((theme) => ({
  hide: {
    display: "none",
  },
  main: {
    display: "flex",
    // width: "100%"
  },
  root: {
    display: "flex",
  },
  content: {
    // display: 'flex',
    padding: theme.spacing(11, 1, 3, 1),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "#dddddd",
    minHeight: `calc(100vh - ${appbarHeight}px)`,
    width: "100vw",
  },
  contentUnShift: {
    width: "100vw",
  },
  contentShift: {
    width: `calc(100vw - ${drawerWidth}px)`,
  },
}));

const getQueryVariable = function (variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};

const login = async () => {
  let currenToken = getQueryVariable("csrfToken");
  if (!currenToken) {
    currenToken = localStorage.getItem("csrfToken");
  }
  localStorage.setItem("csrfToken", currenToken);
  if (currenToken && currenToken !== "null" && currenToken !== "undefined") {
    localStorage.setItem("contrato", "");
    localStorage.setItem("proyecto", "");
    await axios
      .post(
        `${URLS.REDIRECT_BASE}/verifySessionCookie`,
        {},
        {
          withCredentials: true,
          crossDomain: true,
          params: {
            _csrf: currenToken,
          },
        }
      )
      .then((response) => {
        if (response.data.token) {
          auth.signInWithCustomToken(response.data.token);
        }
      })
      .catch((error) => {
        window.location.href = `${URLS.LOGIN}/?origin=${
          window.location.href.split("?")[0]
        }`;
      });
  } else {
    window.location.href = `${URLS.LOGIN}/?origin=${
      window.location.href.split("?")[0]
    }`;
  }
};

export const UserContext = createContext({
  usuarioSesion: null,
  setAccessError: () => {},
  setPermisos: () => {},
  permisos: [],
  accessError: false,
});

export const ConfiguracionContext = createContext({
  configuracion: null,
  setConfiguracion: () => {},
});

export const ContratoContext = createContext({
  setOpenSelectorContrato: () => {},
  contratoSeleccionado: null,
  openSelectorContrato: false,
  setContratoSeleccionado: () => {},
});

export const MainContext = createContext({
  usuarioSesion: null,
  accessError: false,
  setAccessError: () => {},
  permisos: {},
  setPermisos: () => {},
  filtrosListaActivos: {},
  setFiltrosListaActivos: () => {},
  initialValuesFiltroActivos: {
    gerencia: null,
    contrato: null,
    tipo: null,
    clase: null,
    activado: "",
    baja: "",
    asignado: "",
  },
  setInitialValuesFiltroActivos: () => {},
});

export default function App(props) {
  const classes = useStyles();

  const [usuarioSesion, setUsuarioSesion] = useState(null);
  const [permisos, setPermisos] = useState({});
  const [accessError, setAccessError] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [contratoSeleccionado, setContratoSeleccionado] = useState(null);
  const [openSelectorContrato, setOpenSelectorContrato] = useState(false);

  const theme = createMuiTheme({
    typography: {
      fontSize: 14,
      htmlFontSize: 17,
      fontFamily: ["Open Sans", "sans-serif"],
    },
    palette: {
      primary: {
        light: "#a64c46",
        main: "#731f1f",
        dark: "#430000",
        contrastText: "#fff",
      },
      secondary: {
        light: "#4f5b62",
        main: "#263238",
        dark: "#000a12",
        contrastText: "#fff",
      },
      blanco: {
        light: "#fff",
        main: "#fff",
        dark: "#fff",
        contrastText: "#fff",
      },
    },
  });

  const checkSession = (isLogged) => {
    try {
      if (isLogged && auth.currentUser) {
        const user = auth.currentUser;

        obtenerDatosSesionUsuario()
          .then((response) => {
            getUsuario_contrato(user.uid)
              .then((usuario) => {
                setUsuarioSesion({
                  _id: usuario.data[0]._id,
                  uid: user.uid,
                  photoUrl: user.photoURL,
                  nombre: user.displayName,
                  email: user.email,
                  ref: response.ref,
                  // gerencia: usuario.gerencia_ref,
                  contrato: usuario.data[0].contratos_ref[0],
                  contratos_visibles: usuario.data[0].contratos_visibles_ref,
                  proyectos_visibles: usuario.data[0].proyectos_visibles_ref,
                });

                if (response.permisos) setPermisos(response.permisos);
                else setPermisos({});
                try {
                  loadContrato();
                } catch (error) {
                  setOpenSelectorContrato(true);
                }
              })
              .catch((error) => {
                console.error(error);
              });
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        login();
      }
    } catch (error) {
      console.error(error);
      // setAccessError(true);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChange(checkSession);
    return () => {
      unsubscribe();
    };
  }, []);

  const loadContrato = async () => {
    const contrato = localStorage.getItem("contrato");

    if (!contrato || contrato === "undefined") {
      setOpenSelectorContrato(true);
      setIsLoading(false);
    } else {
      let contratoResult = await getContrato(contrato);
      setContratoSeleccionado(contratoResult);
      console.log(contratoResult);
      setOpenSelectorContrato(false);
      setIsLoading(false);
    }
    return;
  };

  const privateRoute = (
    { component: Component, permiso, path, ...rest },
    permisos
  ) => {
    return (
      <Route
        path={path}
        {...rest}
        render={(props) => {
          if (accessError) {
            return <Redirect to="/accessError" />;
          }
          if (!accessError && path === ROUTES.ACCESS_ERROR) {
            return <Redirect to="/" />;
          }
          if (permiso && usuarioSesion && !permiso[permiso]) {
            return <Redirect to="/" />;
          }
          return <Component {...props} />;
        }}
      />
    );
  };

  const mainApp = (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
        <CssBaseline />

        <UserContext.Provider
          value={{
            usuarioSesion,
            setAccessError,
            permisos,
            accessError,
          }}
        >
          <ContratoContext.Provider
            value={{
              contratoSeleccionado: contratoSeleccionado,
              setContratoSeleccionado: setContratoSeleccionado,
              openSelectorContrato: openSelectorContrato,
              setOpenSelectorContrato: setOpenSelectorContrato,
            }}
          >
            
              <div
                style={{
                  paddingTop: "80px",
                  backgroundColor: "#E9E9E9",
                  overflow: "hidden",
                  display:"flex",
                }}
                className={classes.root}
              >
                <AppBar nombreModulo="Log AST" />

                <div
                  style={{
                    marginLeft: "10px",
                    width: "100%",
                    marginRight: "10px",
                    
                  }}
                >
                  <Switch>
                    <Route
                      exact={true}
                      path={ROUTES.HOME}
                      component={() => <Listado />}
                    />
                    <Route
                      exact={true}
                      path={ROUTES.CONFIGURACION}
                      component={() => <Config />}
                    />
                  </Switch>
              </div>
            </div>
            <SelectorContratos
              setLoading={setIsLoading}
              setAccessError={setAccessError}
            />
          </ContratoContext.Provider>
        </UserContext.Provider>
        {/* </main> */}
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
  return isLoading ? <LoadPage></LoadPage> : mainApp;
}
