import Axios from "../axios";

export const getContrato = async (id) => {
  return new Promise((resolve, reject) => {
    var base = process.env.REACT_APP_DB_URL;
    Axios.get(`${base}/contratos/${id}`, {})
      .then((res) => {
        if (res.data) resolve(res.data);
        else resolve(null);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getContratos = async () => {
  return new Promise((resolve, reject) => {
    var base = process.env.REACT_APP_DB_URL;
    Axios.get(`${base}/contratos/`, {})
      .then((res) => {
        if (res.data) resolve(res.data);
        else resolve(null);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getProyectosContrato = async (id) => {
  return new Promise((resolve, reject) => {
    var base = process.env.REACT_APP_DB_URL;
    // var base = "https://api-proyecto.cydocs.cl";
    Axios.get(`${base}/contratos/${id}/proyectos`, {})
      .then((res) => {
        if (res.data && res.data.proyectos_ref) resolve(res.data.proyectos_ref);
        else resolve(null);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getSuperintendenciasContrato = (contratoID) => {
  return new Promise((resolve, reject) => {
    try {
      var base = process.env.REACT_APP_DB_URL;
      // var base = "https://api-proyecto.cydocs.cl";
      Axios.get(`${base}/contratos/${contratoID}/superintendencias`, {})
        .then((res) => {
          if (res.data && res.data.superintendencias_ref)
            resolve(res.data.superintendencias_ref);
          else resolve([]);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    } catch (error) {
      console.error(
        "Error al intentar obtener las superintendencias de un contrato",
        error
      );
      resolve(error);
    }
  });
};

export const getContratistasContrato = async (id) => {
  return new Promise((resolve, reject) => {
    var base = process.env.REACT_APP_DB_URL;
    Axios.get(`${base}/contratos/${id}/contratistas`, {})
      .then((res) => {
        if (res.data && res.data.contratistas_ref)
          resolve(res.data.contratistas_ref);
        else resolve(null);
      })
      .catch((error) => {
        console.log(error);
        resolve(error);
      });
  });
};

export const updateContrato = async (id, params) => {
  return new Promise((resolve, reject) => {
    var base = process.env.REACT_APP_DB_URL;
    Axios.put(`${base}/contratos/${id}`, params)
      .then((res) => {
        if (res.data) resolve(res.data);
        else resolve(null);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const deleteContrato = async (id, params) => {
  return new Promise((resolve, reject) => {
    var base = process.env.REACT_APP_DB_URL;
    Axios.delete(`${base}/contratos/${id}`, {})
      .then((res) => {
        if (res.data) resolve(res.data);
        else resolve(null);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const postContrato = async (body) => {
  return new Promise((resolve, reject) => {
    var base = process.env.REACT_APP_DB_URL;
    Axios.post(`${base}/contratos/`, body)
      .then((res) => {
        if (res.data) resolve(res.data);
        else resolve(null);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};

export const getResponsablePortafolio = async (codigo) => {
  return new Promise((resolve, reject) => {
    var base = process.env.REACT_APP_API_PORTAFOLIO;
    Axios.get(`${base}/contratos/?codigo=${codigo}`)
      .then((res) => {
        console.log(res.data);
        if (res.data) resolve(res.data);
        else resolve(null);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
