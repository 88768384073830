const defaultState = {open: false, mensaje: "", titulo: ""};

export const accionDialogoMensaje = (type, mensaje, titulo) => ({
  type: type,
  mensaje: mensaje,
  titulo: titulo
});

export default function reducerDialogoMensaje(state = defaultState, { type, mensaje, titulo }) {
  switch (type) {
    case "OPEN": {
      return {open: true, mensaje: mensaje, titulo: titulo};
    }
    case "CLOSE": {
      return {open: false, mensaje: mensaje, titulo: titulo};
    }
    default:
      return state;
  }
}