import "../../App.css";

import React, { useContext, useEffect, useRef, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { FormDialogue, FormGenerator } from "formik-generator-materialui";
import {
  getConfiguracion,
  insertConfiguracionRegistroPeligro,
  insertConfiguracionRegistroMedida,
  insertConfiguracionRegistroEquipo,
} from "../../services/database/configuracion";
import { ContratoContext, UserContext } from "../../App";
import Masonry from "react-masonry-css";
import MaterialTable from "material-table";
import { ObtenerUsuarios } from "./requestUsuarios";
import useSWR from "swr";
import {
  getListadoUsuarios,
  guardarUsuario,
} from "../../services/database/usuario";
import AddUsuarios from "./addUsuarios";
import ConfigListas from "./ConfigListas";
import { verificarUsuarioAutorizado } from "../../services/database/listadoAST";
import { useHistory } from "react-router-dom";
import AddAdmin from "./addAdmin";
// import AppBar from "../../components/appBar";
function Configuracion() {
  const formRefPeligros = useRef(null);
  const formRefMedidasControl = useRef(null);
  const formRefEquipos = useRef(null);

  const history = useHistory();
  const [peligrosDeLaTarea, setPeligrosDeLaTarea] = useState([]);
  const [medidasDeControl, setMedidasDeControl] = useState([]);
  const [equiposEpp, setEquiposEpp] = useState([]);
  const [listadoUsuarios, setListadoUsuarios] = useState([]);

  const [peligrosDeLaTareaForm, setPeligrosDeLaTareaForm] = useState([]);
  const [medidasDeControlForm, setPMedidasDeControlForm] = useState([]);
  const [equiposEppForm, setEquiposEppForm] = useState([]);

  const [openModalUsuarios, setOpenModalUsuarios] = useState(false);

  const { contratoSeleccionado } = useContext(ContratoContext);
  const { usuarioSesion } = useContext(UserContext);

  const [configuracion, setConfiguracion] = useState([]);

  useEffect(() => {
    console.log("INIT APP.JS", contratoSeleccionado, "<--");
    console.log(contratoSeleccionado);
    if (contratoSeleccionado) {
      loadConfig();
    }
  }, [contratoSeleccionado]);

  const loadConfig = async () => {
    let config = await getConfiguracion(contratoSeleccionado._id);
    console.log(config);
    setConfiguracion(config);
  };

  useEffect(() => {
    console.log("init config page");
    verificarUsuarioAutorizado(usuarioSesion.uid, contratoSeleccionado).then(
      (isValid) => {
        if (!isValid) history.push("/");
      }
    );
  }, []);
  const { data: usuarios } = useSWR("usuarios", (key) => ObtenerUsuarios(), {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    console.log(configuracion.length);
    if (configuracion.length !== 0) {
      const peligrosDeLaTarea = Object.keys(
        configuracion.peligros_de_la_tarea
      ).map((key, index) => ({
        ...configuracion.peligros_de_la_tarea[key],
        id: key,
      }));
      const equiposEpp = Object.keys(configuracion.equipos_epp).map(
        (key, index) => ({
          ...configuracion.equipos_epp[key],
          id: key,
        })
      );
      const medidasDeControl = Object.keys(
        configuracion.medidas_de_control
      ).map((key, index) => ({
        ...configuracion.medidas_de_control[key],
        id: key,
      }));
      
    }
  }, [configuracion]);

  useEffect(() => {
    console.log(peligrosDeLaTarea);
    let resultArray = [];
    if (peligrosDeLaTarea.length !== 0) {
      resultArray = peligrosDeLaTarea.map((peligro) => {
        return {
          title: "Nombre",
          separator: " ",
          display: [peligro.nombre],
          typeField: "displayValue",
        };
      });
    }
    console.log(resultArray);
    setPeligrosDeLaTareaForm(resultArray);
  }, [peligrosDeLaTarea]);

  useEffect(() => {
    console.log(medidasDeControl);
    let resultArrayMedida = [];
    if (medidasDeControl.length !== 0) {
      resultArrayMedida = medidasDeControl.map((medida) => {
        return {
          title: "Nombre",
          separator: " ",
          display: [medida.nombre],
          typeField: "displayValue",
        };
      });
    }
    console.log(resultArrayMedida);
    setPMedidasDeControlForm(resultArrayMedida);
  }, [medidasDeControl]);

  useEffect(() => {
    console.log(equiposEpp);
    let resultArrayMedida = [];
    if (equiposEpp.length !== 0) {
      resultArrayMedida = equiposEpp.map((medida) => {
        return {
          title: "Nombre",
          separator: " ",
          display: [medida.nombre],
          typeField: "displayValue",
        };
      });
    }
    console.log(resultArrayMedida);
    setEquiposEppForm(resultArrayMedida);
  }, [equiposEpp]);

  const getUsuariosDelContratoFirebase = async () => {
    let newUsuarios = await getListadoUsuarios(contratoSeleccionado._id);
    setListadoUsuarios(newUsuarios);
  };

  const valuesToArrayPeligrosDeLaTarea = () => {
    let resultArray = [];
    if (peligrosDeLaTarea.length !== 0) {
      resultArray = peligrosDeLaTarea.map((peligro) => {
        return {
          title: "Nombre",
          separator: " ",
          display: [peligro.nombre],
          typeField: "displayValue",
        };
      });
    }
    console.log(resultArray);
    setPeligrosDeLaTareaForm(resultArray);
  };

  const convertArrayToObject = (array, key) => {
    const { v4: uuidv4 } = require("uuid"); // ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
    const initialValue = {};
    console.log(array.peligros_de_la_tarea, key);

    array.peligros_de_la_tarea_add.forEach((element) => {
      console.log(element, contratoSeleccionado._id);
      insertConfiguracionRegistroPeligro(contratoSeleccionado._id, element);
    });

    loadConfig();
  };

  const convertArrayToObjectMedida = (array, key) => {
    const { v4: uuidv4 } = require("uuid"); // ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
    const initialValue = {};

    array.medidas_de_control_add.forEach((element) => {
      console.log(element, contratoSeleccionado._id);
      insertConfiguracionRegistroMedida(contratoSeleccionado._id, element);
    });

    loadConfig();
  };

  const convertArrayToObjectEquipo = (array, key) => {
    const { v4: uuidv4 } = require("uuid"); // ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
    const initialValue = {};

    array.equipo_epp_add.forEach((element) => {
      console.log(element, contratoSeleccionado._id);
      insertConfiguracionRegistroEquipo(contratoSeleccionado._id, element);
    });

    loadConfig();
  };

  const [value, setValue] = useState(0);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Paper
        style={{
          width: "100%",
          marginBottom: "5px",
          marginRight: "30px",
          padding: "1rem",
        }}
      >
        <Grid container>
          <Grid item xs={6}>
            <Typography
              variant="h5"
              style={{ textAlign: "center" }}
            >{`Nombre Contrato: ${contratoSeleccionado.nombre} `}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="h5"
              style={{ textAlign: "center" }}
            >{`Código Contrato: ${contratoSeleccionado.codigo} `}</Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Usuarios" />
              <Tab label="Configuración" />
              <Tab label="Asignar Administrador" />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            {value === 0 ? <AddUsuarios /> : null}
          </TabPanel>

          <TabPanel value={value} index={1}>
            {value === 1 ? <ConfigListas /> : null}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {value === 2 ? <AddAdmin /> : null}
          </TabPanel>
        </div>
      </Paper>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default Configuracion;
