import React, { useContext, useEffect, useState } from "react";
import { getConfiguracion } from "../../services/database/configuracion";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Tooltip from "@material-ui/core/Tooltip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import {
  ConfiguracionContext,
  ContratoContext,
  MainContext,
  UserContext,
} from "../../App";
import {
  getListadoAST_Contrato,
  getListadoAST_Contrato_Area,
  getListadoAST_Contrato_Proyecto,
  getListadoAST_Contrato_Proyecto_Area,
} from "../../services/database/listadoAST";
import {
  Backdrop,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
  Box,
  Button,
  makeStyles,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import HomeAst from "../HomeAst";
import MaterialTable from "material-table";
import moment from "moment";
import ErrorIcon from "@material-ui/icons/Error";
import PersonIcon from "@material-ui/icons/Person";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import WorkIcon from "@material-ui/icons/Work";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 4,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 25,
    width: "50%",
    marginTop: "3rem",
    // padding: theme.spacing(2, 4, 3),
  },

  modalStyle1: {
    position: "absolute",
    // float: ,
    // top:'50%',
    // left:'50%',
    overflow: "scroll",
    height: "auto",
    width: "100%",
    display: "block",
    margin: "auto",

    cursor: "grab",
  },

  bold: {
    fontWeight: "bold",
    color: "black",
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));

export default function Listado() {
  const [proyectoSeleccionado, setProyectoSeleccionado] = useState(null);
  const [areaSeleccionada, setAreaSeleccionada] = useState(null);
  const [listadoAST, setListadoAST] = useState([]);
  const [configuracion, setConfiguracion] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [infoAST, setInfoAST] = React.useState();
  const classes = useStyles();

  const { contratoSeleccionado } = useContext(ContratoContext);
  const { setAccessError, setPermisos, permisos, accessError } =
    useContext(UserContext);

  function generate(element) {
    return [0, 1, 2].map((value) =>
      React.cloneElement(element, {
        key: value,
      })
    );
  }
  let fechasOrdenadas = [];

  useEffect(() => {
    // console.log(
    //   contratoSeleccionado && !proyectoSeleccionado && !areaSeleccionada
    // );

    if (contratoSeleccionado && !proyectoSeleccionado && !areaSeleccionada) {
      getListadoAST_Contrato(contratoSeleccionado._id)
        .then((listado) => {
          fechasOrdenadas = listado.sort(
            (a, b) =>
              new moment(a.fecha).format("YYYYMMDD") -
              new moment(b.fecha).format("YYYYMMDD")
          );
          setListadoAST(fechasOrdenadas.reverse());
        })
        .catch((error) =>
          console.error("errore al obternet lista ast" + error)
        );
    } else if (
      contratoSeleccionado &&
      proyectoSeleccionado &&
      !areaSeleccionada
    ) {
      getListadoAST_Contrato_Proyecto(
        contratoSeleccionado._id,
        proyectoSeleccionado._id
      )
        .then((listado) => {
          fechasOrdenadas = listado.sort(
            (a, b) =>
              new moment(a.fecha).format("YYYYMMDD") -
              new moment(b.fecha).format("YYYYMMDD")
          );
          setListadoAST(fechasOrdenadas.reverse());
        })
        .catch((error) =>
          console.error("errore al obternet lista ast filtrado por proeycto")
        );
    } else if (
      contratoSeleccionado &&
      proyectoSeleccionado &&
      areaSeleccionada
    ) {
      getListadoAST_Contrato_Proyecto_Area(
        contratoSeleccionado._id,
        proyectoSeleccionado._id,
        areaSeleccionada._id
      )
        .then((listado) => {
          fechasOrdenadas = listado.sort(
            (a, b) =>
              new moment(a.fecha).format("YYYYMMDD") -
              new moment(b.fecha).format("YYYYMMDD")
          );
          setListadoAST(fechasOrdenadas.reverse());
        })
        .catch((error) =>
          console.log("errore al obternet lista ast filtrado por area")
        );
    } else if (
      contratoSeleccionado &&
      !proyectoSeleccionado &&
      areaSeleccionada
    ) {
      getListadoAST_Contrato_Area(
        contratoSeleccionado._id,
        areaSeleccionada._id
      )
        .then((listado) => {
          fechasOrdenadas = listado.sort(
            (a, b) =>
              new moment(a.fecha).format("YYYYMMDD") -
              new moment(b.fecha).format("YYYYMMDD")
          );
          setListadoAST(fechasOrdenadas.reverse());
        })
        .catch((error) =>
          console.error("errore al obternet lista ast filtrado por area")
        );
    }
  }, [contratoSeleccionado, proyectoSeleccionado, areaSeleccionada]);

  useEffect(() => {
    console.log("INIT APP.JS", contratoSeleccionado, "<--");
    if (contratoSeleccionado) {
      const loadConfig = async () => {
        let config = await getConfiguracion(contratoSeleccionado._id);
        //console.log(config);
        setConfiguracion(config);
      };
      loadConfig();
    }
  }, [contratoSeleccionado]);

  let columns = [
    {
      title: "Fecha",
      field: "fecha",
      width: "10%",
      align: "center",
      render: (rowData) => {
        if (typeof rowData?.fecha === "undefined") {
          return "--";
        } else {
          return moment(rowData?.fecha).format("DD-MM-YYYY HH:mm");
        }
      },
    },
    {
      title: "Responsable",
      field: "responsable.nombre",
      width: "30%",
      align: "center",
      render: (rowData) => getEncargadoView(rowData),
    },
    {
      title: "Nombre Tarea ",
      field: "nombre_tarea",
      width: "30%",
      align: "center",
    },
    {
      title: "Participantes ",
      field: "participantes.length",
      width: "10%",
      align: "center",
      grouping:false, 
      render: (rowData) => getListadoView(rowData),
    },
    {
      title: "Ubicación",
      field: "ubicacion",
      width: "20%",
      align: "center",
    },
  ];

  let location = {
    toolbar: {
      addRemoveColumns: "Agregar o Quitar",
      exportTitle: "Exportar",
      exportCSVName: "Exportar Excel",
      exportPDFName: "Exportar PDF",
      nRowsSelected: "{0} fila(s) selecionadas",
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
      showColumnsTitle: "Mostrar Columnas",
    },
    pagination: {
      labelRowsSelect: "Filas",
      labelDisplayedRows: "{from}-{to} de {count}",
      firstAriaLabel: "Primera Página",
      firstTooltip: "Primera Página",
      previousAriaLabel: "Página Anterior",
      previousTooltip: "Página Anterior",
      nextAriaLabel: "Siguiente Página",
      nextTooltip: "Siguiente Página",
      lastAriaLabel: "Última Página",
      lastTooltip: "Última Página",
    },
    grouping: {
      placeholder: "Arrastre los encabezados aquí para agrupar ",
    },
    header: {
      actions: "Acciones",
    },
    body: {
      emptyDataSourceMessage: "Sin Registros",
      filterRow: {
        filterTooltip: "Filtrar",
      },
    },
  };

  let options = {
    pageSize: 10,
    filtering: true,
    overflowY: "auto",
    padding: "dense",
    grouping: true,
    sorting: true,
    headerStyle: {
      backgroundColor: "#731f1f",
      color: "#FFF",
      fontWeight: "bold",
      textAlign: "center",
      style: { height: "20px" },
    },
  };

  let actions = [
    (rowData) => ({
      icon: () =>
        rowData.pdf_id ? (
          <PictureAsPdfIcon style={{ color: "black" }} />
        ) : (
          <PictureAsPdfOutlinedIcon style={{ color: "gray" }} />
        ),
      onClick: (e, rowData) =>
        rowData.pdf_id ? RedirectToPDF(rowData.pdf_id) : "",
    }),
    {
      icon: "search",
      tooltip: "Información",
      onClick: (e, rowData) => desplegarInfo(rowData),
    },
  ];

  const handleChangeProyecto = (proyecto) => {
    console.log(proyecto);
    setProyectoSeleccionado(proyecto);
    setAreaSeleccionada(null);
    //setContrato(contrato)
  };

  const handleChangeArea = (area) => {
    console.log(area);
    setAreaSeleccionada(area);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const desplegarInfo = (rowData) => {
    setInfoAST(rowData);
    console.log(rowData);
    handleOpenModal();
  };

  return (
    <ConfiguracionContext.Provider
      value={{
        configuracion,
      }}
    >
      <MainContext.Provider
        value={{
          proyectoSeleccionado,
          areaSeleccionada,
          accessError,
          setAccessError,
          permisos,
          setPermisos,
          setProyectoSeleccionado,
          setAreaSeleccionada,
          listadoAST,
        }}
      >
        <React.Fragment>
          <Grid
            style={{
              marginLeft: 14,
              marginRight: 14,
              
            }}
          >
            
            {listadoAST ? (
              <>
                <HomeAst
                  onProyectoSeleccionado={handleChangeProyecto}
                  onAreaSeleccionada={handleChangeArea}
                />
                <div style={{ marginBottom: "20px" }}>
                  <MaterialTable
                    title="Registros AST"
                    is_loading={!listadoAST}
                    data={listadoAST ? listadoAST : []}
                    localization={location}
                    columns={columns}
                    actions={actions}
                    options={options}
                    style={{borderRadius:"10px"}}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </React.Fragment>
        <Modal
          align="center"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalStyle1}
          open={openModal}
          overflow="scroll"
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={classes.paper}>
              <Paper style={{ borderRadius: 25 }}>
                <div>
                  <Grid
                    container
                    style={{
                      backgroundColor: "#731f1f",
                      borderTopLeftRadius: 25,
                      borderTopRightRadius: 25,
                    }}
                  >
                    <Grid item xs={11}>
                      <Typography
                        variant="h5"
                        style={{
                          marginTop: "1rem",
                          paddingLeft: "3rem",
                          color: "white",
                          marginBottom: "1rem",
                        }}
                      >
                        <strong>INFORMACIÓN REGISTRO AST</strong>
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <label
                        htmlFor="icon-button-file"
                        style={{ display: "flex", direction: "rtl" }}
                      >
                        <IconButton
                          style={{ color: "white" }}
                          // color="secondary"
                          aria-label="upload picture"
                          component="span"
                          onClick={handleCloseModal}
                        >
                          <CloseIcon />
                        </IconButton>
                      </label>
                    </Grid>
                  </Grid>
                </div>
                {infoAST && (
                  <Grid
                    style={{ padding: 25 }}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid
                      md={6}
                      item
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="primary"
                        >
                          <FiberManualRecordIcon
                            style={{ paddingTop: "0.5rem" }}
                          />
                          Nombre de la Tarea:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          className={classes.bold}
                          variant="h6"
                          gutterBottom
                        >
                          {infoAST.nombre_tarea}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      md={6}
                      item
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="primary"
                        >
                          <FiberManualRecordIcon
                            style={{ paddingTop: "0.5rem" }}
                          />{" "}
                          Ubicación:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h6"
                          className={classes.bold}
                          gutterBottom
                        >
                          {infoAST.ubicacion}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      md={6}
                      item
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="primary"
                        >
                          <FiberManualRecordIcon
                            style={{ paddingTop: "0.5rem" }}
                          />
                          Responsable:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h6"
                          className={classes.bold}
                          gutterBottom
                        >
                          {infoAST.responsable.nombre}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      md={6}
                      item
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="primary"
                        >
                          <FiberManualRecordIcon
                            style={{ paddingTop: "0.5rem" }}
                          />{" "}
                          Fecha:
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h6"
                          className={classes.bold}
                          gutterBottom
                        >
                          {moment(infoAST.fecha).format("DD-MM-YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Typography
                        variant="subtitle1"
                        align="left"
                        color="primary"
                        gutterBottom
                      >
                        <FiberManualRecordIcon
                          style={{ paddingTop: "0.5rem" }}
                        />
                        Peligros asociados
                      </Typography>
                      <div className={classes.demo}>
                        <List dense={true}>
                          {Array.from(infoAST.identificacion_de_peligros).map(
                            (d, index) => {
                              if (d.is_checked) {
                                return (
                                  <ListItem>
                                    <ListItemIcon>
                                      <ErrorIcon style={{ color: "red" }} />
                                    </ListItemIcon>
                                    <ListItemText primary={d.nombre} />
                                  </ListItem>
                                );
                              }
                            }
                          )}
                        </List>
                      </div>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Typography
                        variant="subtitle1"
                        align="left"
                        color="primary"
                        gutterBottom
                      >
                        <FiberManualRecordIcon
                          style={{ paddingTop: "0.5rem" }}
                        />{" "}
                        Medidas de control
                      </Typography>
                      <div className={classes.demo}>
                        <List dense={true}>
                          {Array.from(infoAST.medidas_de_control).map(
                            (d, index) => {
                              console.log(d);
                              if (d.is_checked) {
                                return (
                                  <ListItem>
                                    <ListItemIcon>
                                      <VerifiedUserIcon
                                        style={{ color: "#ffc400" }}
                                      />
                                    </ListItemIcon>
                                    <ListItemText primary={d.nombre} />
                                  </ListItem>
                                );
                              }
                            }
                          )}
                        </List>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Typography
                        variant="subtitle1"
                        align="left"
                        color="primary"
                        gutterBottom
                      >
                        <FiberManualRecordIcon
                          style={{ paddingTop: "0.5rem" }}
                        />{" "}
                        Equipos
                      </Typography>
                      <div className={classes.demo}>
                        <List dense={true}>
                          {Array.from(infoAST.equipos).map((d, index) => {
                            if (d.is_checked) {
                              return (
                                <ListItem>
                                  <ListItemIcon>
                                    <WorkIcon style={{ color: "blue" }} />
                                  </ListItemIcon>
                                  <ListItemText primary={d.nombre} />
                                </ListItem>
                              );
                            }
                          })}
                        </List>
                      </div>
                    </Grid>
                    <Grid
                      item
                      md={6}
                      container
                      direction="column"
                      justify="flex-start"
                      alignItems="flex-start"
                    >
                      <Typography
                        variant="subtitle1"
                        align="left"
                        color="primary"
                        gutterBottom
                      >
                        <FiberManualRecordIcon
                          style={{ paddingTop: "0.5rem" }}
                        />{" "}
                        Participantes
                      </Typography>
                      <div className={classes.demo}>
                        <List dense={true}>
                          {Array.from(infoAST.participantes).map((d, index) => {
                            return (
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar alt={d.nombre} src={d.photo} />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={`${d.nombre} / ${d.cargo}`}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </div>
          </Fade>
        </Modal>
      </MainContext.Provider>
    </ConfiguracionContext.Provider>
  );
}

function RedirectToPDF(pdf_id) {
  window.open("https://drive.google.com/file/d/" + pdf_id + "/view");
}

function getEncargadoView(data) {
  let texto = "Sin Responsable";
  let foto = "";

  // console.log(typeof data);
  // console.log(data);
  if (typeof data === "string") {
    texto = data;
  } else if (typeof data === "object" && data.responsable) {
    texto = data.responsable.nombre;
    foto = data.responsable.photo;
  }
  return (
    <div style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}>
      <Avatar alt="Foto Encargado" src={foto} />
      <Box fontSize={13} m={1}>
        {texto}
      </Box>
    </div>
  );
}

function getListadoView(data) {
  let texto = "Sin Responsable";
  let foto = "";

  // console.log(typeof data);
  // console.log(data);
  // if (typeof data === "string") {
  //   texto = data;
  // } else if (typeof data === "object" && data.responsable) {
  //   texto = data.responsable.nombre;
  //   foto = data.responsable.photo;
  // }
  return (
    <div style={{ display: "flex", flexFlow: "row ", alignItems: "center" }}>
      <AvatarGroup max={4}>
      {data.participantes.map((user,i) => {
        return (
          <Tooltip key={i} title={user.nombre}>
            <Avatar alt={user.nombre} src={user.photo} />
          </Tooltip>
        );
      })}
    </AvatarGroup>
    </div>
  );
}
