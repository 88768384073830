import { ADMINISTRACION_USUARIOS } from "../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener todos los usuarios.
 */
export async function ObtenerUsuarios() {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/personas`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}