import "../../App.css";

import React, { useContext, useEffect, useState } from "react";
import { FormGenerator } from "formik-generator-materialui";
import {FormDialogue}  from "../../lib/formik-ui-material/src"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  OutlinedInput,
  Paper,
} from "@material-ui/core";
import { getConfiguracion } from "../../services/database/configuracion";
import { ContratoContext } from "../../App";
import MaterialTable from "material-table";
import { ObtenerUsuarios } from "./requestUsuarios";
import useSWR from "swr";
import {
  getListadoUsuarios,
  guardarUsuario,
  eliminarUsuario,
} from "../../services/database/usuario";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PictureAsPdfOutlinedIcon from "@material-ui/icons/PictureAsPdfOutlined";

function ConfigUsuarios() {
  const [listadoUsuarios, setListadoUsuarios] = useState([]);
  const [openModalUsuarios, setOpenModalUsuarios] = useState(false);
  const [listadoFiltrado, setListadoFiltrado] = useState([]);
  const [dialogEliminar, setDialogEliminar] = useState(false);
  const [eliminarID, setEliminarID] = useState("");

  const { contratoSeleccionado } = useContext(ContratoContext);

  const [configuracion, setConfiguracion] = useState([]);

  const handleEliminar = (row) => {
    console.log("asdaasdsa" + row);
    // formik.setValues(row);
    // formik.setFieldValue("tipo", "eliminar");
  };

  useEffect(() => {
    console.log("INIT APP.JS", contratoSeleccionado, "<--");
    console.log(contratoSeleccionado);
    if (contratoSeleccionado) {
      loadConfig();
    }
  }, [contratoSeleccionado]);

  const loadConfig = async () => {
    let config = await getConfiguracion(contratoSeleccionado._id);
    setConfiguracion(config);
  };

  useEffect(() => {
    console.log("init config page");
  }, []);

  const { data: usuarios } = useSWR("usuarios", (key) => ObtenerUsuarios(), {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (configuracion.length !== 0) {
      let usuarios = [];
      if (typeof configuracion?.usuarios !== "undefined") {
        usuarios = Object.keys(configuracion.usuarios).map(
          (key, index) => ({
            ...configuracion.usuarios[key],
            id: key,
          })
        );
      }
      
      console.log(usuarios);
      setListadoUsuarios(usuarios);
    }
  }, [configuracion]);

  useEffect(() => {
    if (usuarios) {
      console.log(usuarios);
      // let usuariosFiltradosPorContrato = Array.from(usuarios).filter((p) => {
      //   if (p.contratos_ref.length > 0) {
      //     return (
      //       p.centro_costos_ref[0]?.codigo.substring(0, 3) ===
      //       contratoSeleccionado.codigo.substring(0, 3)
      //     );
      //   }
      //   return false;
      // });

      let arrayNombreRutFormateado = usuarios.map( item => {
        return {
          ...item,
          rutNombreFormato: `[${item.run}] ${item.nombre_completo} [${item.contacto.email}]`
        }
      })

      // let arrayNombreRutFormateado = usuariosFiltradosPorContrato.map( item => {
      //   return {
      //     ...item,
      //     rutNombreFormato: `[${item.run}] ${item.nombre_completo}`
      //   }
      // })
      setListadoFiltrado(arrayNombreRutFormateado);
      // console.log(usuariosFiltradosPorContrato);
    }
  }, [usuarios]);

  const getUsuariosDelContratoFirebase = async () => {
    let newUsuarios = await getListadoUsuarios(contratoSeleccionado._id);
    setListadoUsuarios(newUsuarios);
  };

  const handleClickOpen = () => {
    setDialogEliminar(true);
  };

  const handleClose = () => {
    setDialogEliminar(false);
  };

  const handleAgree = () => {
    eliminarUsuario(contratoSeleccionado._id, eliminarID);
    handleClose();
    setEliminarID("");
    loadConfig();
  };
  const handleDisagree = () => {
    setEliminarID("");
    handleClose();
  };

  return (
    <React.Fragment>
      <div>
        <Grid item md={12}>
          <MaterialTable
            title={"Usuarios en el contrato"}
            options={{
              filtering: true,
              pageSizeOptions: [10, 20, 30, 40, 50, 60],
              pageSize: 10,
              grouping: true,
              exportButton: false,
              actionsColumnIndex: 0,
            }}
            localization={{
              toolbar: {
                searchPlaceholder: "Buscar",
                exportName: "Exportar CSV",
              },

              pagination: {
                labelRowsSelect: "Filas",
                labelDisplayedRows: "{from}-{to} de {count}",
              },
              grouping: {
                placeholder: " Arrastre los encabezados aquí para agrupar ",
              },
              header: {
                actions: "Acción",
              },
            }}
            columns={[
              {
                title: "Nombre",
                field: "nombre",
                cellStyle: {
                  // fontSize: '0.8em'
                  width: "3px",
                },
              },
              {
                title: "Rut",
                field: "rut",
                cellStyle: {
                  // fontSize: '0.8em'
                  width: "3px",
                },
              },
              {
                title: "Correo",
                field: "email",
                cellStyle: {
                  // fontSize: '0.8em'
                  width: "3px",
                },
              },
              {
                title: "Cargo",
                field: "cargo",
                cellStyle: {
                  // fontSize: '0.8em'
                  width: "3px",
                },
              },
            ]}
            actions={[
              {
                tooltip: "Agregar",
                icon: "add",
                onClick: () => {
                  setOpenModalUsuarios(true);
                },
                isFreeAction: true,
              },

              {
                tooltip: "Eliminar",
                icon: "delete",
                onClick: (e, rowData) => {
                  console.log(rowData);
                  setEliminarID(rowData.id);
                  handleClickOpen();
                },
                // onClick: (e, rowData) => {
                //   console.log(rowData);
                //   handleEliminar(rowData);
                // },
              },
            ]}
            data={listadoUsuarios}
          />
        </Grid>
      </div>

      

      <FormDialogue
        // onError={(error) => console.log("error", error)}
        onSubmitWithError={(error) => console.log("error", error)}
        readOnly={false}
        open={openModalUsuarios}
        onCancel={() => setOpenModalUsuarios(false)}
        okText="Agregar"
        cancelText= "Cancelar"
        freeSolo={true}
        onOk={async (values) => {
          let user = values.user;

          await guardarUsuario(contratoSeleccionado._id, {
            run_formateado: user.run_formateado,
            rut: user.run,
            nombre: user.nombre_completo,
            cargo: user.cargo,
            photo: user.photo,
            uid: user.usuario_id,
            email: user.contacto.email,
          });

          getUsuariosDelContratoFirebase();
        }}
        initialValues={{
          user: {
            nombre_completo:"Nombre del Participante",
            run_formateado:"Rut"
          }
        }}
        title={"Lista de Usuarios"}
        text={"Seleccione el usuario para agregar a los registros de AST"}
        fields={[
          {
            title: "Usuarios",
            path: "user",
            typeField: "autocomplete",
            options: listadoFiltrado,
            getOptionLabel: (user) => {
              return user.rutNombreFormato;
            },           
            
          },
        ]}
      />

      <Dialog
        open={dialogEliminar}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Eliminar Usuario"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Está seguro de eliminar este usuario de la lista?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAgree} color="primary" autoFocus>
            <strong>Eliminar</strong>
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ConfigUsuarios;
