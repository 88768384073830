import {
    SET_INSPECCIONES,
  } from '../actions/inspeciones';
  
  const defaultState = [{
    inspecciones: [],
  }];
  
  export default function reducerSesion(state = defaultState, { type, inspecciones }) {
    switch (type) {
      case SET_INSPECCIONES:
        if (!inspecciones) {
          return state;
        }
        return { ...state, inspecciones: inspecciones }
      default:
        return state;
    }
  }
  