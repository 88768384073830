
export const ADMINISTRACION_USUARIOS = 'https://api-usuarios.cydocs.cl';
export const BACKEND_PORTAFOLIO = 'https://api-portafolio.cydocs.cl';
export const BACKEND = 'https://api-activos.cydocs.cl';
export const BACKEND_SDA = 'https://api-sda.cydocs.cl/v1';
export const FRONTEND = 'https://activos.cydocs.cl';
// export const BACKEND = 'http://localhost:8000';
export const LOGIN = 'https://account.cydocs.cl';
export const PORTAFOLIO = 'https://api-portafolio.cydocs.cl';
export const REDIRECT_BASE = 'https://api-usuarios.cydocs.cl/login';
export const ADMINISTRACION_PROYECTOS = 'https://api-proyecto.cydocs.cl';