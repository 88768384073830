import React, { useContext, useState } from "react";
import { Grid, Paper } from "@material-ui/core";

import InformacionContrato from "./components/informacionContrato";
import Filtros from "./components/Filtros";
import EstadisticasAst from "./components/EstadisticasAst";
import { ConfiguracionContext } from "../../App";

export default function HomeAst(props) {
  const { onProyectoSeleccionado, onAreaSeleccionada } = props;
  const { configuracion } = useContext(ConfiguracionContext);
  const [proyecto, setProyecto] = useState([]);

  const onProyectoSeleccionadoDos = (proyecto) => {
    onProyectoSeleccionado(proyecto);
    setProyecto(proyecto);
  };
  return (
    <React.Fragment>
      <Paper
        elevation={0}
        style={{
          paddingBottom: "20px",
          marginBottom: "20px",
          borderRadius: "10px",
        }}
      >
        <Grid
          container
          style={{ padding: "10px" }}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid item xd={12} md={4}>
            <InformacionContrato />
          </Grid>

          {!configuracion.is_registro_por_contrato && (
            <Grid item xd={12} md={4}>
              <Filtros
                onProyectoSeleccionado={onProyectoSeleccionadoDos}
                onAreaSeleccionada={onAreaSeleccionada}
              />
            </Grid>
          )}

          <Grid item xs={4}>
            <EstadisticasAst proyecto={proyecto} />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
