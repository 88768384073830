import { Box, Grid, Typography, Paper, IconButton, Button } from "@material-ui/core";
import React, { useContext } from "react";
import { ContratoContext } from "../../../App";
import AutorenewIcon from "@material-ui/icons/Autorenew";
const InformacionContrato = (props) => {
  const { contratoSeleccionado, setOpenSelectorContrato } =
    useContext(ContratoContext);

  return (
    <Box marginRight="10px">
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={1}
        style={{marginBottom:"0.5rem"}}
      >
        <Grid item>
          <Typography variant="h6" align={"center"} style={{color:"black"}}>
            Información del Contrato
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            aria-label="upload picture"
            component="span"
            onClick={() => setOpenSelectorContrato(true)}
          >
            <AutorenewIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid container style={{ paddingTop: 5 }}>
        <Grid item xs={12}>
          <Paper variant="outlined" style={{ marginBottom: "3px", borderColor:"lightgray" }}>
            {contratoSeleccionado ? (
              <div style={{ paddingLeft: "5px" }}>
                <Typography style={{ color: "gray" }}>
                  Nombre del contrato:
                </Typography>
                <Typography variant="h6" color="primary" style={{paddingLeft:"0.5rem" }}>
                  <strong>{contratoSeleccionado.nombre}</strong>
                </Typography>
              </div>
            ) : (
              ""
            )}

            {contratoSeleccionado ? (
              <div style={{ paddingLeft: "5px", paddingTop:"1rem" }}>
                <Typography style={{ color: "gray" }}>
                  Código del contrato:
                </Typography>
                <Typography variant="h6" style={{ color: "black", paddingLeft:"0.5rem" }}>
                  {contratoSeleccionado.codigo}
                </Typography>
              </div>
            ) : (
              ""
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InformacionContrato;
