import React, { Fragment, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@material-ui/core/";
import * as ROUTES from "../../constants/routes";
import * as PERMISOS from "../../constants/permissions";
import { DrawerContext } from "../appBar";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { ContratoContext, MainContext, UserContext } from "../../App";
import {
  People as PeopleIcon,
  List as ListIcon,
  Settings as SettingsIcon,
} from "@material-ui/icons";
import { verificarUsuarioAutorizado } from "../../services/database/listadoAST";

export default function MainDrawer(props) {
  const [openCollapse, setOpenCollapse] = useState("");
  const { openMainDrawer, setOpenMainDrawer } = useContext(DrawerContext);
  const { permisos } = useContext(MainContext);
  const { usuarioSesion } = useContext(UserContext);
  const { contratoSeleccionado } = useContext(ContratoContext);
  const [usuarioAdmin, setUsuarioAdmin] = useState(false);

  const handleClickCollapse = (index) => {
    if (index === openCollapse) setOpenCollapse("");
    else setOpenCollapse(index);
  };

  const handleClose = () => setOpenMainDrawer(false);
  const classes = useStyles();

  console.log(contratoSeleccionado);

  useEffect(() => {
    try {
      console.log(usuarioSesion.uid);
      console.log(contratoSeleccionado);
      verificarUsuarioAutorizado(
        usuarioSesion.uid,
        contratoSeleccionado
      ).then((isValid) => {
        setUsuarioAdmin(isValid);
      });
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    verificarUsuarioAutorizado(
      usuarioSesion.uid,
      contratoSeleccionado
    ).then((isValid) => {
      setUsuarioAdmin(isValid);
    }); 

  },[contratoSeleccionado])

  return (
    <Fragment>
      <Drawer
        // ESTO ES PARA MANTENER PERMANENTEMENTE EL DRAWE Y EMPUJE EL CONTENIDO
        variant="permanent"
        // ----------------
        anchor="left"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openMainDrawer,
          [classes.drawerClose]: !openMainDrawer,
        })}
        classes={{
          paper: clsx(classes.drawerMargin, {
            [classes.drawerOpen]: openMainDrawer,
            [classes.drawerClose]: !openMainDrawer,
          }),
        }}
        onClose={handleClose}
        open={openMainDrawer}
      >
        <Divider />
        <List style={{ marginTop: "4rem" }}>
          {/* HOME / MIS ACTIVOS / MIS SOLICITUDES */}
          <ListItem
            button
            component={Link}
            to={ROUTES.LANDING}
            onClick={handleClose}
          >
            <ListItemIcon>
              <ListIcon color="primary" />
            </ListItemIcon>
            <ListItemText
              primary="AST's"
              primaryTypographyProps={{ className: classes.drawerItem }}
            />
          </ListItem>
          {usuarioAdmin && (
            <ListItem
              button
              component={Link}
              to={ROUTES.CONFIGURACION}
              onClick={handleClose}
            >
              <ListItemIcon>
                <SettingsIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Configuración"
                primaryTypographyProps={{ className: classes.drawerItem }}
              />
            </ListItem>
          )}
        </List>
      </Drawer>
    </Fragment>
  );
}

const drawerWidth = 200;
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 26,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerMargin: {
    // marginTop: "48px"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflow: "hidden",
    // width: theme.spacing(0) + 1,
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {},
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: "0 8px",
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  title: {
    padding: theme.spacing(1, 2, 1),
  },
  drawerItem: {
    fontWeight: "regular",
  },
}));
