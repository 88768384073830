import { combineReducers } from 'redux';
import openSelectorContrato from './reducerSelectorContrato';
import reducerDrawerMain from '../../containers/drawerMain/reducer';
import usuarioSesion from './reducerDataUsuario';
import contratoSesion from './reducerContratoUsuario';
import reducerDialogoMensaje from './reducerDialogoMensaje';
import reducerInspecciones from "./reducerInspecciones";
const reducer = combineReducers({
    openSelectorContrato: openSelectorContrato,
    usuarioSesion: usuarioSesion,
    contratoSesion: contratoSesion,
    dialogoMensaje: reducerDialogoMensaje,
    openMenuLateral: reducerDrawerMain,
    inspecciones: reducerInspecciones,
});


export default reducer;