import "../../App.css";

import React, { useContext, useEffect, useRef, useState } from "react";

import {
  AppBar,
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { FormDialogue, FormGenerator } from "formik-generator-materialui";
import {
  getConfiguracion,
  insertConfiguracionRegistroPeligro,
  insertConfiguracionRegistroMedida,
  insertConfiguracionRegistroEquipo,
} from "../../services/database/configuracion";
import { ContratoContext } from "../../App";
import Masonry from "react-masonry-css";
import { ObtenerUsuarios } from "./requestUsuarios";
import useSWR from "swr";
import {
  getListadoUsuarios,
  guardarUsuario,
} from "../../services/database/usuario";
import { Alert } from "@material-ui/lab";
// import AppBar from "../../components/appBar";
function Configuracion() {
  const formRefPeligros = useRef(null);
  const formRefMedidasControl = useRef(null);
  const formRefEquipos = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [textoMedida, setTextoMedida] = React.useState("");

  const [peligrosDeLaTarea, setPeligrosDeLaTarea] = useState([]);
  const [peligrosDeLaTareaAgregados, setPeligrosDeLaTareaAgregados] = useState(
    []
  );
  const [medidasDeControl, setMedidasDeControl] = useState([]);
  const [medidasDeControlAgregados, setMedidasDeControlAgregados] = useState(
    []
  );
  const [equiposEpp, setEquiposEpp] = useState([]);
  const [equiposEppAgregados, setEquiposEppAgregados] = useState([]);
  const [listadoUsuarios, setListadoUsuarios] = useState([]);

  const [peligrosDeLaTareaForm, setPeligrosDeLaTareaForm] = useState([]);
  const [peligrosDeLaTareaAgregadosForm, setPeligrosDeLaTareaAgregadosForm] =
    useState([]);
  const [medidasDeControlForm, setMedidasDeControlForm] = useState([]);
  const [medidasDeControlAgregadosForm, setMedidasDeControlAgregadosForm] =
    useState([]);
  const [equiposEppForm, setEquiposEppForm] = useState([]);
  const [equiposEppAgregadosForm, setEquiposEppAgregadosForm] = useState([]);

  const [openModalUsuarios, setOpenModalUsuarios] = useState(false);

  const { contratoSeleccionado } = useContext(ContratoContext);

  const [configuracion, setConfiguracion] = useState([]);

  useEffect(() => {
    console.log("INIT APP.JS", contratoSeleccionado, "<--");
    console.log(contratoSeleccionado);
    if (contratoSeleccionado) {
      loadConfig();
    }
  }, [contratoSeleccionado]);

  const loadConfig = async () => {
    let config = await getConfiguracion(contratoSeleccionado._id);
    console.log(config);
    setConfiguracion(config);
  };

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    console.log("init config page");
  }, []);
  const { data: usuarios } = useSWR("usuarios", (key) => ObtenerUsuarios(), {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    console.log(configuracion.peligros_de_la_tarea);
    if (configuracion.length !== 0) {
      const peligrosDeLaTarea = Object.keys(
        configuracion.peligros_de_la_tarea
      ).map((key, index) => ({
        ...configuracion.peligros_de_la_tarea[key],
        id: key,
      }));

      const equiposEpp = Object.keys(configuracion.equipos_epp).map(
        (key, index) => ({
          ...configuracion.equipos_epp[key],
          id: key,
        })
      );
      const medidasDeControl = Object.keys(
        configuracion.medidas_de_control
      ).map((key, index) => ({
        ...configuracion.medidas_de_control[key],
        id: key,
      }));
      const usuarios = Object.keys(configuracion.usuarios).map(
        (key, index) => ({
          ...configuracion.usuarios[key],
          id: key,
        })
      );

      formRefPeligros.current.resetForm();
      formRefMedidasControl.current.resetForm();
      formRefEquipos.current.resetForm();
      setListadoUsuarios(usuarios);

      // SEPARAR PELIGROS FIJOS CON AGREGADOS
      var ArrayPeligrosFijos = peligrosDeLaTarea.filter(function (el) {
        return el.id.charAt(0) == "p";
      });
      var ArrayPeligrosIngresados = peligrosDeLaTarea.filter(function (el) {
        return el.id.charAt(0) != "p";
      });
      setPeligrosDeLaTarea(ArrayPeligrosFijos);
      setPeligrosDeLaTareaAgregados(ArrayPeligrosIngresados);

      // SEPARAR MEDIDAS FIJAS CON AGREGADAS
      var ArrayMedidasFijos = medidasDeControl.filter(function (el) {
        return el.id.charAt(0) == "m";
      });
      var ArrayMedidasIngresados = medidasDeControl.filter(function (el) {
        return el.id.charAt(0) != "m";
      });
      setMedidasDeControl(ArrayMedidasFijos);
      setMedidasDeControlAgregados(ArrayMedidasIngresados);

      // SEPARAR EQUIPO EPP FIJOS CON AGREGADOS
      var ArrayEquipoFijos = equiposEpp.filter(function (el) {
        return el.id.charAt(0) == "e";
      });
      var ArrayEquipoIngresados = equiposEpp.filter(function (el) {
        return el.id.charAt(0) != "e";
      });
      setEquiposEpp(ArrayEquipoFijos);
      setEquiposEppAgregados(ArrayEquipoIngresados);
    }
  }, [configuracion]);

  useEffect(() => {
    console.log(peligrosDeLaTarea);
    let resultArray = [];
    let resultArrayAgregados = [];
    if (peligrosDeLaTarea.length !== 0) {
      resultArray = peligrosDeLaTarea.map((peligro) => {
        return {
          title: "Nombre",
          separator: " ",
          display: [peligro.nombre],
          typeField: "displayValue",
        };
      });
    }

    // if (peligrosDeLaTareaAgregados.length !== 0) {
    //   resultArrayAgregados = peligrosDeLaTareaAgregados.map((peligro) => {
    //     return {
    //       title: "Nombre",
    //       separator: " ",
    //       display: [peligro.nombre],
    //       typeField: "displayValue",
    //     };
    //   });
    // }
    console.log(resultArray);
    setPeligrosDeLaTareaForm(resultArray);
    setPeligrosDeLaTareaAgregadosForm(peligrosDeLaTareaAgregados);
  }, [peligrosDeLaTarea]);

  useEffect(() => {
    console.log(medidasDeControl);
    let resultArrayMedida = [];
    if (medidasDeControl.length !== 0) {
      resultArrayMedida = medidasDeControl.map((medida) => {
        return {
          title: "Nombre",
          separator: " ",
          display: [medida.nombre],
          typeField: "displayValue",
        };
      });
    }
    console.log(resultArrayMedida);
    setMedidasDeControlForm(resultArrayMedida);
    setMedidasDeControlAgregadosForm(medidasDeControlAgregados);
  }, [medidasDeControl]);

  useEffect(() => {
    console.log(equiposEpp);
    let resultArrayMedida = [];
    if (equiposEpp.length !== 0) {
      resultArrayMedida = equiposEpp.map((medida) => {
        return {
          title: "Nombre",
          separator: " ",
          display: [medida.nombre],
          typeField: "displayValue",
        };
      });
    }
    console.log(resultArrayMedida);
    setEquiposEppForm(resultArrayMedida);
    setEquiposEppAgregadosForm(equiposEppAgregados);
  }, [equiposEpp]);

  const getUsuariosDelContratoFirebase = async () => {
    let newUsuarios = await getListadoUsuarios(contratoSeleccionado._id);
    setListadoUsuarios(newUsuarios);
  };

  const valuesToArrayPeligrosDeLaTarea = () => {
    let resultArray = [];
    if (peligrosDeLaTarea.length !== 0) {
      resultArray = peligrosDeLaTarea.map((peligro) => {
        return {
          title: "Nombre",
          separator: " ",
          display: [peligro.nombre],
          typeField: "displayValue",
        };
      });
    }
    console.log(resultArray);
    setPeligrosDeLaTareaForm(resultArray);
  };

  const convertArrayToObject = (array, key) => {
    const { v4: uuidv4 } = require("uuid"); // ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
    const initialValue = {};

    if(array.peligros_de_la_tarea_add.length == 0){
      let element = "vacio";
      insertConfiguracionRegistroPeligro(
        contratoSeleccionado._id,
        element,
        configuracion
      );
    }

    array.peligros_de_la_tarea_add.forEach((element) => {
      if (element.nombre != "") {
        console.log(element, contratoSeleccionado._id);
        insertConfiguracionRegistroPeligro(
          contratoSeleccionado._id,
          element,
          configuracion
        );
      }
    });

    loadConfig();
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const convertArrayToObjectMedida = (array, key) => {
    const { v4: uuidv4 } = require("uuid"); // ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
    const initialValue = {};

    if(array.medidas_de_control_add.length == 0){
      let element = "vacio";
      insertConfiguracionRegistroMedida(
        contratoSeleccionado._id,
        element,
        configuracion
      );
    }

    array.medidas_de_control_add.forEach((element) => {
      if (element.nombre != "") {
        console.log(element, contratoSeleccionado._id);
        insertConfiguracionRegistroMedida(
          contratoSeleccionado._id,
          element,
          configuracion
        );
      }
    });

    loadConfig();
  };

  const convertArrayToObjectEquipo = (array, key) => {
    const { v4: uuidv4 } = require("uuid"); // ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
    const initialValue = {};

    if(array.equipo_epp_add.length == 0){
      let element = "vacio";
      insertConfiguracionRegistroEquipo(
        contratoSeleccionado._id,
        element,
        configuracion
      );
    }

    array.equipo_epp_add.forEach((element) => {
      if (element.nombre != "") {
        console.log(element, contratoSeleccionado._id);
        insertConfiguracionRegistroEquipo(
          contratoSeleccionado._id,
          element,
          configuracion
        );
      }
    });

    loadConfig();
  };

  const [value, setValue] = useState(1);

  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper>
        <div className={classes.root}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Masonry
              breakpointCols={3}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              <div>
                <Paper style={{ padding: 10 }}>
                  {/* LISTA DE PELIGROS DE LA TAREA */}
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    <strong>Peligros de la Tarea</strong>
                  </Typography>

                  {/* LISTADO DE PELIGROS FIJOS */}
                  <FormGenerator
                    onSubmit={(val) => {
                      let result = convertArrayToObject(val, "uid");
                      console.log(result);
                      console.log(val);
                    }}
                    // initialValues={{
                    //   peligros_de_la_tarea: peligrosDeLaTarea,
                    // }}
                    fields={peligrosDeLaTareaForm}
                  />

                  <FormGenerator
                    formRef={formRefPeligros}
                    onSubmit={(val) => {
                      let result = convertArrayToObject(val, "uid");
                      console.log(result);
                      console.log(val);
                    }}
                    initialValues={{
                      peligros_de_la_tarea_add: peligrosDeLaTareaAgregadosForm,
                    }}
                    fields={[
                      {
                        title: "Nuevos Peligros Agregados",
                        path: "peligros_de_la_tarea_add",
                        subfields: [
                          {
                            title: "Nombre",
                            name: "nombre",
                            typeField: "text",
                            col: 12,
                          },
                        ],
                        typeField: "arrayObject",
                        emptyAddText: "Agregar Nuevo(s) Peligro(s)",
                      },
                    ]}
                  />
                  <Box style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        formRefPeligros.current.submitForm();
                        formRefEquipos.current.submitForm();
                        formRefMedidasControl.current.submitForm();
                        
                        setTextoMedida("los peligros de la tarea")
                        handleClick();
                        //
                      }}
                    >
                      Confirmar Ingreso Nuevos Peligros
                    </Button>
                  </Box>
                </Paper>
              </div>

              {/* LISTA DE MEDIDAS DE CONTROL */}
              <div>
                <Paper style={{ padding: 10 }}>
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    <strong>Medidas de Control</strong>
                  </Typography>

                  <FormGenerator
                    onSubmit={(val) => {
                      let result = convertArrayToObjectMedida(val, "uid");
                      console.log(result);
                      console.log(val);
                    }}
                    initialValues={{
                      medidas_de_control: medidasDeControl,
                    }}
                    fields={medidasDeControlForm}
                  />

                  <FormGenerator
                    formRef={formRefMedidasControl}
                    onSubmit={(val) => {
                      let result = convertArrayToObjectMedida(val, "uid");
                      console.log(result);
                      console.log(val);
                    }}
                    initialValues={{
                      medidas_de_control_add: medidasDeControlAgregadosForm,
                    }}
                    fields={[
                      {
                        title: "Nuevas Medidas de Control Agregadas",
                        path: "medidas_de_control_add",
                        subfields: [
                          {
                            title: "Nombre",
                            name: "nombre",
                            typeField: "text",
                            col: 12,
                          },
                        ],
                        typeField: "arrayObject",
                        emptyAddText: "Agregar Nueva(s) Medidas(s)",
                      },
                    ]}
                  />
                  <Box style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        formRefMedidasControl.current.submitForm();
                        formRefEquipos.current.submitForm();                        
                        formRefPeligros.current.submitForm();
                        setTextoMedida("las medidas de control")
                        handleClick();
                        //
                      }}
                    >
                      Confirmar Ingreso Nuevas Medidas
                    </Button>
                  </Box>
                </Paper>
              </div>

              {/* EQUIPOS EPP */}
              <div>
                <Paper style={{ padding: 10 }}>
                  <Typography variant="h5" style={{ textAlign: "center" }}>
                    <strong>Equipos EPP</strong>
                  </Typography>

                  <FormGenerator
                    onSubmit={(val) => {
                      let result = convertArrayToObjectEquipo(val, "uid");
                      console.log(result);
                      console.log(val);
                    }}
                    initialValues={{
                      equipos_epp: equiposEpp,
                    }}
                    fields={equiposEppForm}
                  />

                  <FormGenerator
                    formRef={formRefEquipos}
                    onSubmit={(val) => {
                      let result = convertArrayToObjectEquipo(val, "uid");
                      console.log(result);
                      console.log(val);
                    }}
                    initialValues={{
                      equipo_epp_add: equiposEppAgregadosForm,
                    }}
                    fields={[
                      {
                        title: "Nuevo EPP Agregados",
                        path: "equipo_epp_add",
                        subfields: [
                          {
                            title: "Nombre",
                            name: "nombre",
                            typeField: "text",
                            col: 12,
                          },
                        ],
                        typeField: "arrayObject",
                        emptyAddText: "Agregar Nuevo EPP",
                      },
                    ]}
                  />
                  <Box style={{ textAlign: "center" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        formRefEquipos.current.submitForm();
                        formRefMedidasControl.current.submitForm();
                        formRefPeligros.current.submitForm();
                        setTextoMedida("los EPPS")
                        handleClick();
                        //
                      }}
                    >
                      Confirmar Ingreso Nuevo EPP
                    </Button>
                  </Box>
                </Paper>
              </div>
            </Masonry>

            <FormDialogue
              // onError={(error) => console.log("error", error)}
              onSubmitWithError={(error) => console.log("error", error)}
              readOnly={false}
              open={openModalUsuarios}
              onCancel={() => setOpenModalUsuarios(false)}
              onOk={async (values) => {
                let user = values.user;

                await guardarUsuario(contratoSeleccionado._id, {
                  run_formateado: user.run_formateado,
                  rut: user.run,
                  nombre: user.nombre_completo,
                  cargo: user.cargo,
                  photo: user.photo,
                  uid: user.usuario_id,
                  email: user.contacto.email,
                });

                getUsuariosDelContratoFirebase();
              }}
              title={"Your title"}
              text={"Your text here"}
              fields={[
                {
                  title: "Usuarios",
                  path: "user",
                  typeField: "autocomplete",
                  options: usuarios,
                  getOptionLabel: (user) => {
                    return user.nombre_completo;
                  },
                },
              ]}
            />
          </Grid>
        </div>
      </Paper>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="success">
          Se ha actualizado {textoMedida} exitosamente.
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default Configuracion;
