import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

function Page(props) {
  const { is_open, set_is_open, contratos, set_contrato_sel } = props;

  return (
    <Dialog open={is_open} maxWidth={"lg"} fullWidth={true}>
      <DialogContent>
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12} lg={12}>
            Seleccione el contato a revisar
            <Autocomplete
              options={contratos || []}
              getOptionLabel={(contrato) =>
                `[${contrato.codigo}] ${contrato.nombre}`
              }
              onChange={(event, value) => {
                set_is_open(false);
                set_contrato_sel(value);
                localStorage.setItem("contrato",value._id);
              }}
              renderInput={(params) => (
                <TextField label="Contrato" variant="outlined" {...params} />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => set_is_open(false)}
          color="primary"
          // disabled={!proyecto || !contrato}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          // disabled={!isValid}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Page;
