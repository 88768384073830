export const ACCESS_ERROR = '/accessError';
export const HOME = '/';
export const LANDING = '/';
export const DASHBOARD = '/dashboard';
export const LOGIN = '/login';
export const PAGE_1 = '/inspecciones';
export const REDIRECT = '/redireccionar';
export const REPORTE_RESUMEN = '/resumen';
export const REPORTE_RESUMEN_SEMANAL_FECHA =  '/resumenSemanal/:fechaIncio_p/:fechaFinal_p'
export const REPORTE_RESUMEN_SEMANAL =  '/resumenSemanal'

export const BIENVENIDA_CYD = "/bienvenida-cyd";
export const NOSOTROS = "/nosotros";
export const PDFPRUEBA = "/pdfprueba"
export const BENEFICIOS = "/beneficios"
export const FORMACIONDESARROLLO = "/formacion-y-desarrollo"
export const NOTICIAS = "/noticias"
export const COMITECUIDADO = "/comite-de-cuidado"
export const RSE ="/rse"
export const GERENTES="/gerentes"
export const CONFIGURACION ="/configuracion"