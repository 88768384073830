import React, { Fragment, useState, useContext, createContext } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Menu as MenuIcon, More } from "@material-ui/icons";
import {
  AppBar as MaterialAppBar,
  Avatar,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { cerrarSesion } from "../../services/auth";
import "./style.css";
import { MainContext, UserContext } from "../../App";
import MainDrawer from "../mainDrawer";
// import logo from "../../img/logo-cydocs-color.png";

const logo = "https://storage.googleapis.com/mi-cyd/PRUEBA%20BPM%20GMI/Cydocs.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 3,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "white",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  infoButton: {
    marginLeft: theme.spacing(2),
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  contenedorUser: {
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "20px",
  },
  titleNoMargin: {
    margin: 0,
    textAlign: "end",
    color: "black",
  },
  titleBold: {
    fontWeight: "bold",
    color: "black",
  },
  hide: {
    display: "none",
  },
  button: {
    zIndex: theme.zIndex.drawer + 2,
    margin: theme.spacing(1),
  },
  input: {
    display: "none",
  },
  homeLink: {
    color: "white",
    textDecoration: "none",
  },
  img: {
    height: "40px",
    marginTop: "12px",
    marginBottom: "12px",
    marginRight: theme.spacing(2),
  },
  cyd_div: {
    height: "40px",
    backgroundColor: "#fff",
    marginTop: "12px",
    marginBottom: "12px",
    paddingTop: "5px",
    // marginRight: theme.spacing(1),
    borderRadius: "20px",
  },
  img_cyd: {
    height: "30px",
  },
}));

export const DrawerContext = createContext({
  setOpenMainDrawer: () => {},
  openMainDrawer: true,
});

export default function AppBar(props) {
  const { nombreModulo } = props;
  const { usuarioSesion } = useContext(UserContext);
  const [openMainDrawer, setOpenMainDrawer] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const mobileMenuId = "primary-search-account-menu-mobile";
  const menuId = "primary-search-account-menu";
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const onClickMenuLateral = () => {
    setOpenMainDrawer(!openMainDrawer);
  };

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }
  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }
  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }
  async function handleCerrarSession() {
    setAnchorEl(null);
    await cerrarSesion();

    handleMobileMenuClose();
  }

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {/* <MenuItem onClick={handleMenuClose}>Perfíl</MenuItem> */}
        <MenuItem onClick={handleCerrarSession}>Cerrar Sesión</MenuItem>
      </Menu>
    );
  };

  const renderMobileMenu = () => {
    return (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={mobileMenuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
      >
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            aria-label="Account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Avatar alt="Remy Sharp" src={""} className={classes.bigAvatar} />
          </IconButton>
          <p>Perfil</p>
        </MenuItem>
      </Menu>
    );
  };

  return (
    <Fragment>
      <div className={classes.root}>
        <MaterialAppBar
          position="fixed"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="primary"
              aria-label="Menu"
              onClick={onClickMenuLateral}
            >
              <MenuIcon />
            </IconButton>
            <Link to="/">
              <img className={classes.img} src={logo} alt="CyDocs" />
            </Link>
            <Typography variant="h6" color="primary" align={"center"}>
              <strong>Análisis Seguro de Trabajo (AST)</strong>
            </Typography>
            <Typography
              className={classes.title}
              variant="h6"
              color="inherit"
            ></Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/* <Tooltip title="Ayuda">
                <IconButton color="inherit" aria-label="Ayuda">
                  <Help />
                </IconButton>
              </Tooltip>
              <div className={classes.cyd_div}>
                <img className={classes.img_cyd} src={logo_cyd} alt="CyDocs" />
              </div> */}
              <Grid container direction="column">
                <div className={classes.contenedorUser}>
                  <Grid item xs>
                    <Typography
                      className={classes.titleNoMargin}
                      variant="body2"
                      gutterBottom
                    >
                      Bienvenido
                    </Typography>
                    <div style={{ whiteSpace: "nowrap" }}>
                      <Box
                        component="div"
                        textOverflow="ellipsis"
                        overflow="hidden"
                      >
                        <Typography
                          className={classes.titleBold}
                          variant="body2"
                          color="inherit"
                        >
                          {usuarioSesion ? usuarioSesion.nombre : ""}
                        </Typography>
                      </Box>
                    </div>
                  </Grid>
                </div>
              </Grid>

              <IconButton
                edge="end"
                aria-label="Account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar
                  alt="Remy Sharp"
                  src={usuarioSesion ? usuarioSesion.photoUrl : ""}
                  className={classes.bigAvatar}
                />
              </IconButton>
            </div>
            <div className={clsx(classes.sectionMobile)}>
              <IconButton
                aria-label="Show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <More />
              </IconButton>
            </div>
          </Toolbar>
        </MaterialAppBar>
        {renderMobileMenu()}
        {renderMenu()}
      </div>
      <DrawerContext.Provider
        value={{
          openMainDrawer: openMainDrawer,
          setOpenMainDrawer: setOpenMainDrawer,
        }}
      >
        <MainDrawer />
      </DrawerContext.Provider>
    </Fragment>
  );
}
