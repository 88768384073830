import {
  Box,
  Grid,
  Typography,
  IconButton,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useContext, useEffect, useState } from "react";
import {
  ConfiguracionContext,
  ContratoContext,
  UserContext,
} from "../../../App";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import Axios from "axios";

const Filtros = (props) => {
  const { onProyectoSeleccionado, onAreaSeleccionada } = props;

  const [proyecto, setProyecto] = useState();
  const [proyectos, setProyectos] = useState();
  const [areasImput, setAreasImput] = useState("");
  const [areas, setAreas] = useState([]);

  const { configuracion } = useContext(ConfiguracionContext);
  const { contratoSeleccionado, setOpenSelectorContrato } =
    useContext(ContratoContext);
  const { usuarioSesion } = useContext(UserContext);

  useEffect(() => {
    if (contratoSeleccionado) {
      ObtenerProyectos(contratoSeleccionado, usuarioSesion)
        .then((proyectos) => {
          setProyectos(proyectos);
        })
        .catch((error) => setProyectos([]));
    }
  }, [contratoSeleccionado, usuarioSesion]);

  useEffect(() => {
    if (proyecto) {
      setAreasImput("");
      ObtenerAreas(contratoSeleccionado, proyecto, usuarioSesion)
        .then((areas_) => setAreas(areas_))
        .catch((error) => setAreas([]));
    }else{
        setAreasImput("");
    }
  }, [proyecto, usuarioSesion, contratoSeleccionado]);

  useEffect(() => {
    if (configuracion.is_registro_por_area) {
      ObtenerAreas_RegistroPorContrato(contratoSeleccionado, usuarioSesion)
        .then((areas_) => setAreas(areas_))
        .catch((error) => setAreas([]));
    }
  }, [configuracion, usuarioSesion, contratoSeleccionado]);

  return (
    <Box marginRight="10px">
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={1}
      >
        <Grid item>
          <Typography variant="h6" align={"center"} style={{paddingLeft:"15px", color:"black"}}>
            Filtros
          </Typography>
        </Grid>
        <Grid item>
          <IconButton
            style={{ visibility: "hidden" }}
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => setOpenSelectorContrato(true)}
          >
            <AutorenewIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          {configuracion?.is_registro_por_proyecto ? (
            <div style={{ paddingLeft: "5px" }}>
              <Autocomplete
                style={{ padding: "5px" }}
                // inputValue={
                //   proyecto ? `[${proyecto.codigo}] ${proyecto.nombre}` : ""
                // }
                options={proyectos || []}
                getOptionLabel={(proyecto) =>
                  `[${proyecto.codigo}] ${proyecto.nombre}`
                }
                onChange={(event, value) => {
                  onProyectoSeleccionado(value);
                  setProyecto(value);
                }}
                renderInput={(params) => (
                  <TextField label="Proyecto" variant="outlined" {...params} />
                )}
              />
            </div>
          ) : (
            ""
          )}
        </Grid>

        <Grid item xs={12}>
          {configuracion?.is_registro_por_proyecto ||
          configuracion?.is_registro_por_area ? (
            <div style={{ paddingLeft: "5px" }}>
              <Autocomplete
                style={{ padding: "5px" }}
                options={areas || []}
                // inputValue={areasImput}
                disabled={!proyecto && !configuracion.is_registro_por_area}
                getOptionLabel={(areas) => `${areas.nombre}`}
                onChange={(event, value) => {
                  onAreaSeleccionada(value);
                  setAreasImput(value ? value.nombre : "");
                }}
                renderInput={(params) => (
                  <TextField label="Área" variant="outlined" {...params} />
                )}
              />
            </div>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

async function ObtenerProyectos(contrato, usuario) {
  try {
    let url = `https://api-proyecto.cydocs.cl/usuarios/${usuario._id}/contratos-visibles`;
    let response = await Axios.get(url);
    let proyectos = response.data.contratos_visibles_ref;
    let proyectosFilter = Array.from(proyectos).filter(
      (p) => p.codigo === contrato.codigo
    );
    return proyectosFilter[0].proyectos_ref;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function ObtenerAreas(contrato, proyecto, usuario) {
  try {
    let url = `https://api-proyecto.cydocs.cl/usuarios/${usuario._id}/contratos-visibles`;
    let response = await Axios.get(url);
    let contratos = response.data.contratos_visibles_ref;
    let contratosFilter = Array.from(contratos).filter(
      (p) => p.codigo === contrato.codigo
    );
    let proyectos = Array.from(contratosFilter[0].proyectos_ref).filter(
      (p) => p.codigo === proyecto.codigo
    );
    return proyectos[0].areas_ref;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

async function ObtenerAreas_RegistroPorContrato(contrato, usuario) {
  try {
    // let url = `https://api-proyecto.cydocs.cl/usuarios/${usuario._id}/contratos-visibles`;
    let url = `https://api-proyecto.cydocs.cl/areas?contrato_ref=${contrato._id}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default Filtros;
