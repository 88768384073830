import { realTime } from "../../firebase";
import { getResponsablePortafolio } from "../contrato";


export const getListadoAST_Contrato = async (idContrato) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/registros")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
            count: index + 1,
          }));
          resolve(registrosList);
        }
        resolve([]);
      });
  });
};

export const getListadoAST_Contrato_Proyecto = async (
  idContrato,
  idProyecto
) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/registros")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
            count: index + 1,
          }));

          let proyectosASTFilter = Array.from(registrosList).filter(
            (p) => p.proyecto === idProyecto
          );
          console.log(proyectosASTFilter);
          resolve(proyectosASTFilter);
        }
        resolve([]);
      });
  });
};

export const getListadoAST_Contrato_Proyecto_Area = async (
  idContrato,
  idProyecto,
  idArea
) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/registros")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
            count: index + 1,
          }));

          let proyectosASTFilterProyecto = Array.from(registrosList).filter(
            (p) => p.proyecto === idProyecto
          );

          let proyectosASTFilter = Array.from(
            proyectosASTFilterProyecto
          ).filter((p) => p.area === idArea);
          resolve(proyectosASTFilter);
        }
        resolve([]);
      });
  });
};

export const getListadoAST_Contrato_Area = async (idContrato, idArea) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/registros")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            uid: key,
            count: index + 1,
          }));
          let proyectosASTFilter = Array.from(registrosList).filter(
            (p) => p.area === idArea
          );
          resolve(proyectosASTFilter);
        }
        resolve([]);
      });
  });
};

export const verificarUsuarioAutorizado =  async (uidUsuario,contrato) =>{

  

  return new Promise((resolve, reject) => {
    console.log(contrato)
    if(contrato){
      realTime
      .ref("apps/ast/" + contrato._id + "/config/usuarios_admin/"+uidUsuario)
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        console.log("apps/ast/" + contrato._id + "/config/usuarios_admin/"+uidUsuario);
        let usuarioProyecto = await getResponsablePortafolio(contrato.codigo)
        console.log(usuarioProyecto[0].responsable_ref.id);
        console.log(usuarioProyecto[0].responsable_admin_ref.id);

        if(registros){
          resolve(true);
        }else{
          if(usuarioProyecto[0].responsable_ref.id === uidUsuario){
            resolve(true);
          }else if(usuarioProyecto[0].responsable_admin_ref.id == uidUsuario){
            resolve(true);
          }else{
            resolve(false);
          }
          
        }
        resolve(false);
      });
    }else{
      resolve(false);
    }
    
  });
}