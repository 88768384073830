import { realTime } from "../../firebase";

export const getConfiguracionProyecto_isRegistroPorProyecto = async (
  idContrato
) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/is_registro_por_proyecto")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();

        if (registros) {
          resolve(registros);
        }
        resolve(null);
      });
  });
};

export const insertConfiguracionRegistroPeligro = async (
  idContrato,
  nombreNuevoTarea,
  configuracion
) => {
  const listadoPeligros = Object.keys(configuracion.peligros_de_la_tarea).map(
    (key, index) => ({
      ...configuracion.peligros_de_la_tarea[key],
      id: key,
    })
  );

  var peligrosIngresadosNuevos = listadoPeligros.filter(function (el) {
    return el.id.charAt(0) != "p";
  });

  peligrosIngresadosNuevos.forEach(function (peligro, index) {
    console.log(peligro.id);
    const newPostRef = realTime
      .ref(
        "apps/ast/" + idContrato + "/config/peligros_de_la_tarea/" + peligro.id
      )
      .set({});
  });

  if(nombreNuevoTarea != "vacio"){
    const newPostRef = realTime
    .ref("apps/ast/" + idContrato + "/config/peligros_de_la_tarea")
    .push()
    .set(nombreNuevoTarea);

  return newPostRef;
  }
  
  return true;
};

export const insertConfiguracionRegistroMedida = async (
  idContrato,
  nombreNuevoTarea,
  configuracion
) => {

  const listadoMedidas = Object.keys(configuracion.medidas_de_control).map(
    (key, index) => ({
      ...configuracion.medidas_de_control[key],
      id: key,
    })
  );

  var medidasIngresadosNuevos = listadoMedidas.filter(function (el) {
    return el.id.charAt(0) != "m";
  });

  medidasIngresadosNuevos.forEach(function (medida, index) {
    const newPostRef = realTime
      .ref(
        "apps/ast/" + idContrato + "/config/medidas_de_control/" + medida.id
      )
      .set({});
  });

  if(nombreNuevoTarea != "vacio"){
  const newPostRef = realTime
    .ref("apps/ast/" + idContrato + "/config/medidas_de_control")
    .push()
    .set(nombreNuevoTarea);

  return newPostRef;

  }

  return true;
};

export const insertConfiguracionRegistroEquipo = async (
  idContrato,
  nombreNuevoTarea,
  configuracion
) => {

  const listadoEquipos = Object.keys(configuracion.equipos_epp).map(
    (key, index) => ({
      ...configuracion.equipos_epp[key],
      id: key,
    })
  );

  var equiposIngresadosNuevos = listadoEquipos.filter(function (el) {
    return el.id.charAt(0) != "e";
  });

  equiposIngresadosNuevos.forEach(function (equipo, index) {
    const newPostRef = realTime
      .ref(
        "apps/ast/" + idContrato + "/config/equipos_epp/" + equipo.id
      )
      .set({});
  });

  console.log(nombreNuevoTarea);
  
  if(nombreNuevoTarea != "vacio"){
  const newPostRef = realTime
    .ref("apps/ast/" + idContrato + "/config/equipos_epp")
    .push()
    .set(nombreNuevoTarea);

  return newPostRef;
  }

  return true;
};

export const getConfiguracion = async (idContrato) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        console.log(registros)
        
        if (registros) {
          resolve(registros);
        }else{
          let configBase =  await getConfiguracionBase();
          console.log(configBase);
          resolve(configBase)

        }
        resolve(null);
      });
  });
};

export const getConfiguracionBase = async () => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/base/config")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        console.log(registros)
        
        if (registros) {
          resolve(registros);
        }else{
          
        }
        resolve(null);
      });
  });
};
export const getListadPeligrosDeLaTarea = async (idContrato) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/peligros_de_la_tarea")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            id: key,
          }));
          resolve(registrosList);
        }
        resolve(null);
      });
  });
};
export const getListaEquiposEpp = async (idContrato) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/equipos_epp")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            id: key,
          }));
          resolve(registrosList);
        }
        resolve(null);
      });
  });
};

export const getListadMedidasDeControl = async (idContrato) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/medidas_de_control")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          const registrosList = Object.keys(registros).map((key, index) => ({
            ...registros[key],
            id: key,
          }));
          resolve(registrosList);
        }
        resolve(null);
      });
  });
};

export const getConfiguracionProyecto_isRegistroPorArea = async (
  idContrato
) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/is_registro_por_area")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          resolve(registros);
        }
        resolve(null);
      });
  });
};

export const getConfiguracionProyecto_isRegistroPorContrato = async (
  idContrato
) => {
  return new Promise((resolve, reject) => {
    realTime
      .ref("apps/ast/" + idContrato + "/config/is_registro_por_contrato")
      .once("value", async (snapshot) => {
        const registros = snapshot.val();
        if (registros) {
          resolve(registros);
        }
        resolve(null);
      });
  });
};
