import React, { useState, useEffect, useContext } from "react";
import Page from "./page";
import { ContratoContext, UserContext } from "../../App";
import Axios from "axios";

export default function SelectorContrato(props) {

  const [contratos, setContratos] = useState();
  
  const {
    usuarioSesion,
    
  } = useContext(UserContext);

  const {
    
    openSelectorContrato,
    setOpenSelectorContrato,
    setContratoSeleccionado,
  } = useContext(ContratoContext);

  useEffect(() => {
    if (usuarioSesion) {
      ObtenerContratos(usuarioSesion)
        .then((contratos) => setContratos(contratos))
        .catch((error) => setContratos([]));
      
    }
  }, [usuarioSesion]);



  return (
    <Page
      is_open={openSelectorContrato}
      set_is_open={setOpenSelectorContrato}
      contratos={contratos}      
      set_contrato_sel={setContratoSeleccionado}
    />
  );
}

async function ObtenerContratos(usuario) {
  try {
    let url = `https://api-proyecto.cydocs.cl/usuarios/${usuario._id}/contratos-visibles`;
    let response = await Axios.get(url);
    let contratos = response.data.contratos_visibles_ref;
    let contratosFilter = Array.from(contratos);
    return contratosFilter;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

