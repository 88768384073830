import { Box, Paper, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment";
import "moment/locale/es";

import { MainContext } from "../../../App";

moment.locale("es");

const EstadisticasAst = (props) => {
  const { listadoAST } = useContext(MainContext);
  const [dataArreglo, setDataArreglo] = useState([]);
  const renderCustomBarLabel = ({ payload, x, y, width, height, value }) => {
    return (
      <text
        x={x + width / 2}
        y={y}
        fill="#666"
        textAnchor="middle"
        dy={-6}
      >{`${value}`}</text>
    );
  };

  const data = [];

  useEffect(() => {
    let today = moment();
    let weekAgo = moment().add(-1, "week").startOf("day");

    let dates = [];
    let day = today;

    while (day >= weekAgo) {
      dates.push({
        fecha: day.format("DD-MMM"),
        data: 0,
      });
      day = day.clone().add(-1, "d");
    }

    dates.forEach((date, index) => {
      dates[index].data = listadoAST.filter(
        (ast) => ast.fecha && moment(ast.fecha).format("DD-MMM") === date.fecha
      ).length;
    });

    setDataArreglo(dates.reverse());
  }, [listadoAST]);

  return (
    <>
      <Paper elevation={0} variant="outlined" style={{ height: "auto",  borderColor:"white" }}>
        {listadoAST.length !== 0 ? (
          <Box style={{textAlign:"-webkit-center"}}>
            <div style={{ width: "95%", height: 200 }}>
              <ResponsiveContainer>
                <BarChart width={400} height={200} data={dataArreglo}>
                  <XAxis dataKey="fecha" />
                  <YAxis
                  domain={[0, 'dataMax + 1']}
                  allowDecimals= {false}
                  padding= {{ top: 0 }}
                  width = {40}
                    label={{
                      value: "AST Registrados",
                      angle: -90,
                      position: "insideBottomLeft",
                    }}

                  />
                  <Bar
                    dataKey="data"
                    barSize={30}
                    fill="#ffc400"
                    // label={renderCustomBarLabel}
                  >
                    <LabelList dataKey="data" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Box>
        ) : (
          <></>
        )}
      </Paper>
    </>
  );
};

export default EstadisticasAst;
